import dayjs from 'dayjs'
import { Button, DateField, DatePicker, EditButton, Form, Icons, Input, List, SaveButton, Space, Table, useEditableTable } from '@pankod/refine-antd'
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { FileWithLink } from 'components/File/FileWithLink'
import { definitions } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT, getIvaOfAmount } from 'utility'
import { AssignInvoiceModal } from './AssignInvoiceModal'
import { SyncInvoicesButton } from './SyncInvoicesButton'
import { UnassignInvoiceModal } from './UnassignInvoiceModal'
import { ComponentProps, MouseEvent, useState } from 'react'
import { DeleteInvoiceModal } from './DeleteInvoiceModal'

type Props = {
  closeFn?: () => void
  marketOrderId?: number
}

type InvoiceMatching = definitions['invoices_matchings'] & { 'market_order_id': definitions['market_orders'] }

export const UnassignedInvoicesTable: React.FC<IResourceComponentsProps & Props> = ({ closeFn, marketOrderId }) => {
  const t = useTranslate()
  const {
    tableProps,
    formProps,
    isEditing,
    setId,
    id,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps
  } = useEditableTable<InvoiceMatching>({
    resource: 'invoices_matchings',
    permanentFilter: [
      {
        field: 'market_order_id',
        operator: marketOrderId ? 'eq' : 'null',
        value: marketOrderId ?? 'null'
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'id, public_url, file_name, created_at, marketplace_legal_name, marketplace_legal_address, marketplace_postal_code, marketplace_province, marketplace_cif, invoice_date, iva_type, iva_amount, market_order_id(purchase_amount)'
    }
  })

  const [ivaType, setIvaType] = useState<string>()
  const [ivaAmount, setIvaAmount] = useState<string>()

  const EditableInput: React.FC<{ name: string, isDate: boolean }> = ({ name, isDate }) => {
    const props: ComponentProps<typeof Form.Item> = {}
    if (isDate) {
      props.getValueProps = (value) => {
        if (value) {
          const date = new Date(Date.parse(value))
          date.setHours(1)
          return { value: dayjs(date) }
        }
        return { value: '' }
      }
    }

    return (
      <Form.Item
        name={name}
        style={{ margin: 0, minWidth: '16em' }}
        {...props}
      >
        {isDate ? <DatePicker /> : <Input />}
      </Form.Item>
    )
  }

  function renderSimpleEditableInput (value: string, record: InvoiceMatching, name: string, isDate = false) {
    if (isEditing(record.id)) return <EditableInput name={name} isDate={isDate} />
    else return value
  }

  function calculateIvaAmount (record: InvoiceMatching, ivaType: string) {
    const purchaseAmount = record.market_order_id.purchase_amount
    const amount = purchaseAmount - getIvaOfAmount(purchaseAmount, (ivaType && parseInt(ivaType) >= 0) ? parseInt(ivaType) : ((!ivaType) ? 21 : 0))
    setIvaAmount(amount.toFixed(2))
    formProps.form?.setFieldsValue({ iva_amount: amount.toFixed(2) })
  }

  return (
    <List
      headerProps={{
        extra: <>
          {!marketOrderId && <SyncInvoicesButton />}
        </>
      }}
      title={t('invoices_matchings.titles.orders')}
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey={(row) => `invoices_matchings-${row.id}`}
          onRow={(record) => ({
            onClick: (event: MouseEvent<HTMLTableElement>) => {
              const target = event.target as HTMLElement
              if (target.nodeName === 'TD') {
                const cell = target as HTMLTableCellElement
                const row = cell.parentElement
                if (row instanceof HTMLTableRowElement) {
                  const cells = Array.from(row.cells)
                  if (cell !== cells[cells.length - 1]) {
                    setId && setId(record.id)
                  }
                }
              }
            }
          })}
        >
          <Table.Column
            key="public_url"
            dataIndex="public_url"
            title={t('invoices_matchings.fields.public_url')}
            align="center"
            render={(value) => <FileWithLink href={value} />}
          />
          <Table.Column
            key="file_name"
            dataIndex="file_name"
            title={t('invoices_matchings.fields.file_name')}
          />
          <Table.Column
            key="created_at"
            dataIndex="created_at"
            title={t('invoices_matchings.fields.created_at')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value} /> }
          />
          <Table.Column
            key="marketplace_legal_name"
            dataIndex="marketplace_legal_name"
            title={t('invoices_matchings.fields.marketplace_legal_name')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'marketplace_legal_name')}
          />
          <Table.Column
            key="marketplace_legal_address"
            dataIndex="marketplace_legal_address"
            title={t('invoices_matchings.fields.marketplace_legal_address')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'marketplace_legal_address')}
          />
          <Table.Column
            key="marketplace_postal_code"
            dataIndex="marketplace_postal_code"
            title={t('invoices_matchings.fields.marketplace_postal_code')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'marketplace_postal_code')}
          />
          <Table.Column
            key="marketplace_province"
            dataIndex="marketplace_province"
            title={t('invoices_matchings.fields.marketplace_province')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'marketplace_province')}
          />
          <Table.Column
            key="marketplace_cif"
            dataIndex="marketplace_cif"
            title={t('invoices_matchings.fields.marketplace_cif')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'marketplace_cif')}
          />
          <Table.Column
            key="invoice_date"
            dataIndex="invoice_date"
            title={t('invoices_matchings.fields.invoice_date')}
            render={(value, record: InvoiceMatching) => renderSimpleEditableInput(value, record, 'invoice_date', true)}
          />
          <Table.Column
            key="iva_type"
            dataIndex="iva_type"
            title={t('invoices_matchings.fields.iva_type')}
            render={(value, record: InvoiceMatching) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item
                    name="iva_type"
                    style={{ margin: 0, minWidth: '16em' }}
                  >
                    <Input type="number" value={id === record.id ? ivaType : value} onChange={(e) => {
                      const ivaType = e.target.value
                      setIvaType(ivaType)
                      calculateIvaAmount(record, ivaType)
                    }} />
                  </Form.Item>
                )
              }

              return value
            }}
          />
          <Table.Column
            key="iva_amount"
            dataIndex="iva_amount"
            title={t('invoices_matchings.fields.iva_amount')}
            render={(value, record: InvoiceMatching) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item
                    name="iva_amount"
                    style={{ margin: 0, minWidth: '16em' }}
                  >
                    <Input value={id === record.id ? ivaAmount : value} readOnly />
                  </Form.Item>
                )
              }

              return value
            }}
          />
          <Table.Column<InvoiceMatching>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, record) => {
              return (
                <Space>
                  {isEditing(record.id)
                    ? (
                        <>
                          <SaveButton {...saveButtonProps} hideText />
                          <Button {...cancelButtonProps} icon={<Icons.StopOutlined />} danger />
                        </>
                      )
                    : (
                        <>
                          <EditButton {...editButtonProps(record.id)} hideText />
                          <AssignInvoiceModal searchOrder={!marketOrderId} record={record} closeFn={closeFn} />
                          {marketOrderId
                            ? <UnassignInvoiceModal record={record} closeFn={closeFn} />
                            : <DeleteInvoiceModal record={record} closeFn={closeFn} />
                          }
                        </>
                      )
                  }
                </Space>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}

import { supabaseClient } from './supabaseClient'
import { ADMITTED_STATUS, API_URL, BASIC_USER_ROLE_ID, CANCELLED_STATUS, CUSTOM_ORDER_TEXT, DEFAULT_IVA, DELIVERED_STATUS, IGIC_TYPE_REDUCIDO, LOW_VALUE_LIMIT, MAX_ROWS_SALES, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_REQUESTED, ORDER_STATUS_ID_PENDING_PAYMENT, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, PREPARED_STATUS, PROCESSED_STATUS, REFUND_STATUS, REFUND_TYPE, RETURN_STATUS, SHIPPED_STATUS, SHIPPING_INSURANCE, SUPPLEMENT_STATUS, TIPSA_AGENCY_CODE, TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED, TIPSA_AGENCY_SERVICE_TYPE_DIRECT } from './constants'
import { currentUserEmail } from './authLocal'
import { subtotal, savings, synivaPrice, total, shippingInsurance } from './orderCalculator'
import { definitions } from 'interfaces'
import { removeNull } from './cleanObjectTypescript'
import { formatDateTime } from './formatDateTime'
import { filter, sorter } from 'utility'
import { MapStatusIDToText, PostgrestFilterBuilder, getReturnDayLimit } from './utils'

export const getOrders = async (select: string, page: number, itemsPerRow: number, filters?: filter[], sorter?: sorter) => {
  let stringFilter = ''
  let stringSorter = ''
  if (filters) {
    filters.forEach((filt: filter) => {
      if (filt.operator === 'not') {
        stringFilter += `.${filt.operator}('${filt.field}', 'is', null)`
      } else if (filt.operator === 'is') {
        stringFilter += `.${filt.operator}('${filt.field}', ${filt.value})`
      } else if (filt.operator === 'in') {
        stringFilter += `.${filt.operator}('${filt.field}', [${filt.value}])`
      } else if (filt.operator === 'or') {
        stringFilter += `.or('${filt.value}')`
      } else if (filt.operator === 'filter') {
        if (filt.secondOp === 'ilike') {
          stringFilter += `.${filt.operator}('${filt.field}', '${filt.secondOp}','%${filt.value}%')`
        } else {
          stringFilter += `.${filt.operator}('${filt.field}', '${filt.secondOp}',${(typeof filt.value === 'string') ? "'" + filt.value + "'" : (Array.isArray(filt.value)) ? '[' + filt.value + ']' : filt.value})`
        }
      } else {
        stringFilter += `.${filt.operator}('${filt.field}',${(typeof filt.value === 'string') ? "'" + filt.value + "'" : (Array.isArray(filt.value)) ? '[' + filt.value + ']' : filt.value})`
      }
    })
  }

  if (sorter) {
    if (sorter.field === 'id') {
      stringSorter = '.order(\'negative_id\', { ascending: true })'
    } else {
      stringSorter = `.order('${sorter.field}', { ascending: ${sorter.ascending} })`
    }
  }

  const stringRange = (page === -1 && itemsPerRow === 0) ? '' : `.range((${page} - 1) * ${itemsPerRow}, ((${page} - 1) * ${itemsPerRow}) + ${itemsPerRow} - 1)`

  const funcBodyCount = `async function func(supabaseClient) { const { count } = await supabaseClient.from('orders').select('${select}', { count: 'exact', head: true })${stringFilter}; return count; }`
  const wrap = (s: any) => `{ return ${funcBodyCount}; }`
  // eslint-disable-next-line no-new-func
  const funcCount = new Function(wrap(funcBodyCount))
  // eslint-disable-next-line no-useless-call
  const count = await funcCount.call(null).call(null, supabaseClient)

  const funcBodyData = `async function func(supabaseClient) { const { data, error } = await supabaseClient.from('orders').select('${select}')${stringFilter}${stringRange}${stringSorter}; return { data, error }; }`
  const wrapData = (s: any) => `{ return ${funcBodyData}; }`
  // eslint-disable-next-line no-new-func
  const funcData = new Function(wrapData(funcBodyData))
  // eslint-disable-next-line no-useless-call
  const { data, error } = await funcData.call(null).call(null, supabaseClient)

  if (error) return { data: [], count: 0 }
  return { data: data, count: count }
}

export const updateCalculatedTotalOrder = async (id: number) => {
  const { data, error } = await supabaseClient.from('orders')
    .select('id, total, discount, wallet_amount_used, supplements(id, deleted, volumetric, description, supplement)')
    .eq('id', id).single()

  if (error) return null

  let orderFinalTotal = data.total || 0

  if (data && data.supplements && data.supplements.length > 0) {
    const volumetricSupplements = data.supplements.filter((s: any) => s.volumetric && !s.deleted)
      .map((s: any) => s.supplement || 0)
      .reduce((a: number, b: number) => a + b, 0)

    const nonVolumetricSupplements = data.supplements.filter((s: any) => !s.volumetric && !s.deleted &&
      s.description !== 'reducedIva' &&
      s.description !== 'reducedIvaSecondHand' &&
      s.description !== 'reducedIvaBreakdown' &&
      s.description !== 'reducedIvaEU' &&
      s.description !== 'reducedIvaShipment' &&
      s.description !== 'refund' &&
      s.description !== 'igic' &&
      s.description !== 'shippingInsurancePrice' &&
      s.description !== 'refundStore' &&
      s.description !== 'refundByCancelation' &&
      s.description !== 'refundTransport' &&
      s.description !== 'transportToPeninsula' &&
      s.description !== 'transportSupplement' &&
      s.description !== 'unified' &&
      s.description !== 'priceChange')
      .map((s: any) => s.supplement || 0)
      .reduce((a: number, b: number) => a + b, 0)

    const refundTransportSupplements = data.supplements.filter((s: any) => !s.volumetric && !s.deleted &&
      (s.description === 'refundTransport' || s.description === 'transportToPeninsula' || s.description === 'transportSupplement'))
      .map((s: any) => s.supplement || 0)
      .reduce((a: number, b: number) => a + b, 0)

    const refundSupplements = data.supplements.filter((s: any) => !s.volumetric && !s.deleted &&
      (s.description === 'refund' || s.description === 'refundStore'))
      .map((s: any) => s.supplement || 0)
      .reduce((a: number, b: number) => a + b, 0)

    // refundByCancelation is a special case, we don't need it in the summary

    orderFinalTotal += volumetricSupplements + nonVolumetricSupplements + refundTransportSupplements + refundSupplements + (data.discount || 0) - (data.wallet_amount_used || 0)
  } else {
    orderFinalTotal += (data.discount || 0) - (data.wallet_amount_used || 0)
  }

  const { error: errorUpdate } = await supabaseClient.from('orders')
    .update({
      current_calculated_total: parseFloat(orderFinalTotal).toFixed(2)
    })
    .eq('id', id)

  if (errorUpdate) return null
  else return id
}

export const getOrderExport = async (select: string, filters?: filter[], sorter?: sorter) => {
  const { data } = await getOrders(select, -1, 0, filters, sorter)
  return data
}

export const findUsersByText = async (value: string) => {
  const { data } = await supabaseClient.from('users')
    .select('id, email')
    .ilike('email', `%${value}%`)
    .eq('role_id', BASIC_USER_ROLE_ID)
    .order('id', { ascending: true })

  return data
}

export const findMarketplacesByText = async (value: string) => {
  const { data } = await supabaseClient.from('marketplaces')
    .select('id, name')
    .ilike('name', `%${value}%`)
    .eq('deleted', false)
    .order('id', { ascending: true })

  return data
}

export const findOrdersByIdWithoutIssues = async (value: string) => {
  const { data } = await supabaseClient
    .from('orders')
    .select('*, issues(order_id), marketplace_id(name), status(id, name), shipping_method(name)')
    .like('id_to_text', `%${value}%`)
    .order('id', { ascending: true })

  return data?.filter(order => order.issues === null)
}

export const getSales = async (select: string, reviewed: boolean = false, gtDate: Date, ltDate: Date) => {
  let counter = 0
  if (reviewed) {
    const { count } = await supabaseClient.from('items')
      .select(`${select}`, { count: 'exact', head: true })
      .eq('deleted', false)
      .eq('reviewed', true)
      .not('order.invoice', 'is', null)
      .gte('order.invoice_date', gtDate)
      .lte('order.invoice_date', ltDate)

    counter = count || 0
  } else {
    const { count } = await supabaseClient.from('items')
      .select(`${select}`, { count: 'exact', head: true })
      .eq('deleted', false)
      .eq('reviewed', false)
      .not('order.invoice', 'is', null)
      .gte('order.invoice_date', gtDate)
      .lte('order.invoice_date', ltDate)
      .eq('order.supplements.deleted', false)
      .eq('order.supplements.reviewed', true)
      .neq('order.supplements.supplement', 0)
      .eq('order.supplements.status_id', ADMITTED_STATUS)
    counter = count || 0
  }

  let maxIterations = Math.floor((counter || 0) / MAX_ROWS_SALES)

  if ((counter || 0) % MAX_ROWS_SALES > 0) {
    maxIterations += 1
  }

  const iterateArray = new Array(maxIterations)
  const promiseArray = []

  const range = [0, MAX_ROWS_SALES - 1]
  // eslint-disable-next-line no-unused-vars
  for (const index of iterateArray) {
    if (reviewed) {
      promiseArray.push(supabaseClient.from('items')
        .select(`${select}`)
        .eq('deleted', false)
        .eq('reviewed', true)
        .not('order.invoice', 'is', null)
        .gte('order.invoice_date', gtDate)
        .lte('order.invoice_date', ltDate)
        .range(range[0], range[1])
      )
    } else {
      promiseArray.push(supabaseClient.from('items')
        .select(`${select}`)
        .eq('deleted', false)
        .eq('reviewed', false)
        .not('order.invoice', 'is', null)
        .gte('order.invoice_date', gtDate)
        .lte('order.invoice_date', ltDate)
        .eq('order.supplements.deleted', false)
        .eq('order.supplements.reviewed', false)
        .neq('order.supplements.supplement', 0)
        .eq('order.supplements.status_id', ADMITTED_STATUS)
        .range(range[0], range[1])
      )
    }
    range[0] += MAX_ROWS_SALES
    range[1] += MAX_ROWS_SALES
  }

  const results = await Promise.all(promiseArray)

  let querySucess: any[] = []
  results.forEach((r) => {
    if (r.data) {
      querySucess = [...querySucess, ...r.data]
    }
  })

  return querySucess
}

export const getPurchases = async (select: string, reviewed: boolean, gtDate: Date, ltDate: Date) => {
  let counter = 0
  if (reviewed) {
    const { count } = await supabaseClient.from('market_orders')
      .select(`${select}`, { count: 'exact', head: true })
      .eq('deleted', false)
      .eq('reviewed', true)
      .gte('invoice_date', gtDate)
      .lte('invoice_date', ltDate)
    counter = count || 0
  } else {
    const { count } = await supabaseClient.from('market_orders')
      .select(`${select}`, { count: 'exact', head: true })
      .eq('deleted', false)
      .eq('reviewed', false)
      .gte('invoice_date', gtDate)
      .lte('invoice_date', ltDate)
    counter = count || 0
  }

  let maxIterations = Math.floor((counter || 0) / MAX_ROWS_SALES)

  if ((counter || 0) % MAX_ROWS_SALES > 0) {
    maxIterations += 1
  }

  const iterateArray = new Array(maxIterations)
  const promiseArray = []

  const range = [0, MAX_ROWS_SALES - 1]
  // eslint-disable-next-line no-unused-vars
  for (const index of iterateArray) {
    if (reviewed) {
      promiseArray.push(supabaseClient.from('market_orders')
        .select(`${select}`)
        .eq('deleted', false)
        .eq('reviewed', true)
        .gte('invoice_date', gtDate)
        .lte('invoice_date', ltDate)
        .range(range[0], range[1])
      )
    } else {
      promiseArray.push(supabaseClient.from('market_orders')
        .select(`${select}`)
        .eq('deleted', false)
        .eq('reviewed', false)
        .gte('invoice_date', gtDate)
        .lte('invoice_date', ltDate)
        .range(range[0], range[1])
      )
    }
    range[0] += MAX_ROWS_SALES
    range[1] += MAX_ROWS_SALES
  }

  const results = await Promise.all(promiseArray)

  let querySucess: any[] = []
  results.forEach((r) => {
    if (r.data) {
      querySucess = [...querySucess, ...r.data]
    }
  })
  return querySucess
}

export const getSupplierExport = async (select: string, filter: filter[] | null) => {
  let dataFind = []

  if (!filter) {
    const { data } = await supabaseClient.from('suppliers')
      .select(select)
      .eq('deleted', false)
    dataFind = data || []
  } else {
    const { data } = await supabaseClient.from('suppliers')
      .select(select)
      .eq('deleted', false)
      .ilike(filter[0].field, `%${filter[0].value}%`)
    dataFind = data || []
  }

  return dataFind
}

export const getVariableValue = async (variableName: string, setState: Function) => {
  const { data } = await supabaseClient
    .from('variables')
    .select('value')
    .eq('name', variableName)
    .single()

  setState(Number(data.value))
}

export const getCountRequestedOrders = async () => {
  const { count } = await supabaseClient
    .from('orders')
    .select('id', { count: 'exact', head: true })
    .eq('status_id', ORDER_STATUS_ID_REQUESTED)
  return count
}

export const getCountPriorityOrders = async () => {
  const { count } = await supabaseClient
    .from('orders')
    .select('id', { count: 'exact', head: true })
    .eq('is_priority', true)
    .in('status_id', [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS])
  return count
}

export const lockOrUnlockOrder = async (orderId: number | undefined, value: boolean) => {
  if (orderId) {
    const lockedBy = value ? currentUserEmail() : null
    await supabaseClient
      .from('orders')
      .update({
        locked: value,
        locked_by: lockedBy,
        updated_by: supabaseClient.auth.user()?.email
      })
      .eq('id', orderId)
  }
}

export const handleDisableItem = async (itemId: number[], value: boolean, shippingPrice?: Number, allItems: boolean = false, shouldReload: boolean = true, orderStatus?: number | undefined) => {
  if (itemId) {
    const { data: tempItem } = await supabaseClient
      .from('items')
      .update({ disabled: value })
      .in('id', itemId)

    const orderId = tempItem ? tempItem[0].order_id : null
    let itemsPrice = tempItem?.map((i) => i.syniva_price * i.quantity)?.reduce((partialSum, a) => partialSum + a, 0)

    const { data: order } = await supabaseClient
      .from('orders')
      .select('items(id, disabled), shipping_method(shipMode)')
      .eq('id', orderId)
      .single()

    const shipMode = order.shipping_method?.shipMode || false
    if (shipMode) {
      itemsPrice = tempItem?.map((i) => i.price * i.quantity)?.reduce((partialSum, a) => partialSum + a, 0)
    }

    // Remove all existing refunds because it is an order with penalized status
    if (allItems) {
      await supabaseClient.from('refunds')
        .update({ deleted: true })
        .eq('order_id', orderId)
        .eq('deleted', false)
    }

    const { shippingInsurance, igic } = await calculateOrderPrices(orderId)

    // Check if need puchase_conditions applications
    if (shipMode && (orderStatus && orderStatus !== PENALIZATION_STATUS)) {
      const { data: initialOrder } = await supabaseClient.from('audit_orders')
        .select('id, record, resource_id')
        .eq('resource_id', orderId)
        .eq('operation', 'INSERT')
        .single()

      const initialSynivaPrice = initialOrder?.record?.syniva_price || 0

      const { data: currentOrder } = await supabaseClient.from('orders').select('id, syniva_price, shipping_price, purchase_conditions').eq('id', orderId).single()
      const purchaseConditions = currentOrder?.purchase_conditions || null
      const shippingPriceToAdd = parseFloat(purchaseConditions.shipment_method_price)

      if (purchaseConditions && purchaseConditions.free_vol && purchaseConditions.free_vol === 'true') {
        const { data: transportSupsData } = await supabaseClient.from('supplements')
          .select('id, supplement, description')
          .eq('order_id', orderId)
          .eq('deleted', false)
          .or(`supplement.eq.${shippingPriceToAdd}, supplement.eq.${-shippingPriceToAdd}`)
          .or('description.eq.refundTransport, description.eq.transportSupplement')

        const totalSupsData = transportSupsData?.map((x: any) => x.supplement).reduce((a: number, b: number) => a + b, 0) || 0

        if (purchaseConditions.free_vol_price_min) {
          if (parseFloat(purchaseConditions.free_vol_price_min) < currentOrder.syniva_price && initialSynivaPrice < parseFloat(purchaseConditions.free_vol_price_min)) {
            // console.log(1, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
            if (totalSupsData === 0) {
              // console.log('A')
              await addTransportSupplementOrRefund(orderId, 'refundTransport', ADMITTED_STATUS, -purchaseConditions.shipment_method_price)
            } else {
              // console.log('B')
            }
          } else if (parseFloat(purchaseConditions.free_vol_price_min) < initialSynivaPrice && currentOrder.syniva_price > parseFloat(purchaseConditions.free_vol_price_min)) {
            // console.log(3, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
            if (totalSupsData === 0) {
              // console.log('A')
            } else {
              // console.log('B')
              await addTransportSupplementOrRefund(orderId, 'refundTransport', ADMITTED_STATUS, -purchaseConditions.shipment_method_price)
            }
          } else if (parseFloat(purchaseConditions.free_vol_price_min) < initialSynivaPrice && currentOrder.syniva_price < parseFloat(purchaseConditions.free_vol_price_min)) {
            // console.log(2, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
            if (totalSupsData === 0) {
              // console.log('A')
              await addTransportSupplementOrRefund(orderId, 'transportSupplement', (purchaseConditions.shipment_method_price > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS, purchaseConditions.shipment_method_price)
            } else {
              // console.log('B')
            }
          } else if (parseFloat(purchaseConditions.free_vol_price_min) > currentOrder.syniva_price && initialSynivaPrice < parseFloat(purchaseConditions.free_vol_price_min)) {
            // console.log(4, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
            if (totalSupsData === 0) {
              // console.log('A')
            } else {
              await addTransportSupplementOrRefund(orderId, 'transportSupplement', (purchaseConditions.shipment_method_price > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS, purchaseConditions.shipment_method_price)
              // console.log('B')
            }
          }
        }
      }
    }

    if (value) {
      const refunds: any = []

      if (shippingInsurance > 0) {
        refunds.push({
          order_id: orderId,
          description: 'Seguro de transporte',
          amount: Math.abs(shippingInsurance),
          type: REFUND_TYPE.client
        })
      }

      let lastItemCanceled = false
      if (!allItems) {
        lastItemCanceled = order.items.find((x: any) => x.disabled === false && x.id !== itemId) === undefined
      } else {
        lastItemCanceled = true
      }

      if (shippingPrice && Number(shippingPrice) > 0 && lastItemCanceled) {
        refunds.push({
          order_id: orderId,
          description: 'Gastos de envío',
          amount: Math.abs(parseFloat(shippingPrice.toString())),
          type: REFUND_TYPE.client
        })
      }

      if (igic > 0) {
        refunds.push({
          order_id: orderId,
          description: 'Igic',
          amount: igic,
          type: REFUND_TYPE.client
        })
      }

      if (itemsPrice && itemsPrice > 0) {
        refunds.push({
          order_id: orderId,
          description: 'Producto',
          amount: itemsPrice,
          type: REFUND_TYPE.client
        })
      }

      if (refunds.length > 0) {
        await supabaseClient
          .from('refunds')
          .insert(refunds)
      }
    }

    if (shouldReload) window.location.reload()
  }
}

export const calculateOrderPrices = async (orderId: number, comeFromShipmentChange: boolean = true) => {
  const { data: items } = await supabaseClient
    .from<definitions['items']>('items')
    .select('quantity, syniva_price, price, tariff_price, igic_price, igic_percent, iva, disabled, has_accessories, shipping_price_to_madrid, url, custom_order_site')
    .eq('order_id', orderId)
    .eq('deleted', false)

  const { data: order } = await supabaseClient
    .from('orders')
    .select('id, created_at, status_id, subtotal, syniva_price, total, type, igic, shipping_price, shipping_insurance_price, shipping_method_id, marketplace_id, shipping_method(price, shipMode), discount, wallet_amount_used, custom_order_price, promotional_code, purchase_conditions')
    .eq('id', orderId)
    .single()

  const { data: marketplace } = await supabaseClient
    .from('marketplaces')
    .select('igic_types(value), id, shipment_price, updated_at, free_shipment_price')
    .eq('id', order.marketplace_id)
    .single()

  const { data: igicTypes } = await supabaseClient
    .from('igic_types')
    .select('id, name, value')

  const { data: marketplaceAudit } = await supabaseClient
    .from('audit')
    .select('id, record, created_at')
    .eq('table_name', 'marketplaces')
    .eq('record->>id', marketplace?.id)
    .order('created_at', { ascending: false })

  const activeItems = items?.filter((row) => !row.disabled) || null

  const shipMode = order.shipping_method?.shipMode || false

  const { data: insurancePercentage } = await supabaseClient.from('variables').select('value').eq('name', SHIPPING_INSURANCE).single()
  const { data: lowValueLimit } = await supabaseClient.from('variables').select('value').eq('name', LOW_VALUE_LIMIT).single()

  const subtotalValue = subtotal(activeItems)
  let savingsValue = savings(activeItems, DEFAULT_IVA)
  let synivaPriceValue = synivaPrice(activeItems)
  // FIXME: Fixed using date, best try to set the order structure with a migration
  let shippingInsuranceValue = shippingInsurance(activeItems, insurancePercentage.value, shipMode)
  const deployDate = new Date('2024-03-06T15:00:00.00000+00:00')
  const createdDate = new Date(order.created_at)
  if (createdDate < deployDate) {
    shippingInsuranceValue = shippingInsurance(activeItems, 2, shipMode)
  }
  let shippingPriceToMadrid = 0

  activeItems?.forEach((i) => {
    if (i.shipping_price_to_madrid && i.shipping_price_to_madrid !== null && shippingPriceToMadrid < i.shipping_price_to_madrid) {
      shippingPriceToMadrid = i.shipping_price_to_madrid
    }
  })

  if (shipMode) {
    savingsValue = 0
    synivaPriceValue = subtotalValue
  }

  const isCustom = order.type.toLowerCase() === CUSTOM_ORDER_TEXT
  let igicPercentage = marketplace.igic_types.value
  if (isCustom) {
    const withAccesories = items?.filter(x => x.has_accessories === true)
    if (withAccesories && withAccesories.length > 0) {
      // eslint-disable-next-line no-unused-vars
      igicPercentage = igicTypes?.find(x => x.name.toLowerCase() === IGIC_TYPE_REDUCIDO)?.value || 3
    }
  }

  let igicValue = 0
  // its for all active items? or only if SynivaPrice > 150 in order
  activeItems?.forEach((i: any) => {
    if (synivaPriceValue > lowValueLimit.value) {
      igicValue += i.igic_price
    }
  })

  let isPromotedFreeShipment = false

  if (marketplaceAudit !== null && marketplaceAudit.length > 0 && order?.created_at) {
    const maWithFreeShipment = marketplaceAudit.filter(x => x.record.shipment_price < 0)
    maWithFreeShipment.forEach((ma) => {
      const index = marketplaceAudit.findIndex((x) => x.id === ma.id)
      const indexEnd = marketplaceAudit.findIndex((x) => x.created_at > marketplaceAudit[index].created_at && x.record.shipment_price > 0)
      const init = marketplaceAudit[index]
      const end = marketplaceAudit[indexEnd] || undefined

      if (init && end !== undefined) {
        const orderDate = new Date(order?.created_at)
        const marketDateStart = new Date(init.created_at)
        const marketDateEnd = new Date(end.created_at)
        const isIn = orderDate.getTime() < marketDateEnd.getTime() && orderDate.getTime() > marketDateStart.getTime()
        if (isIn) {
          isPromotedFreeShipment = true
        }
      } else if (init && end === undefined) {
        const orderDate = new Date(order?.created_at)
        const marketDateStart = new Date(init.created_at)
        const isIn = orderDate.getTime() > marketDateStart.getTime()
        if (isIn) {
          isPromotedFreeShipment = true
        }
      }
    })
  }

  let shippingPriceValue = 0
  if (activeItems && activeItems.length > 0) {
    if (!isPromotedFreeShipment) {
      const marketplaceShipmentPrice = (subtotalValue < marketplace?.free_shipment_price) ? marketplace?.shipment_price || 0 : 0
      shippingPriceValue = order?.shipping_method.price + marketplaceShipmentPrice
    } else {
      shippingPriceValue = order?.shipping_method.price - Math.abs(marketplace?.shipment_price)
    }
  }

  shippingPriceValue = shippingPriceValue + shippingPriceToMadrid

  // ONLY FOR ECONOMY if flag FREE_SHIPMENT_ECONOMY is enabled set to 0 de shipment price
  const purchaseConditions = order?.purchase_conditions

  if (purchaseConditions !== null && purchaseConditions.free_vol === 'true') {
    if (purchaseConditions.free_vol_price_min && purchaseConditions.free_vol_price_min !== '' && subtotalValue >= Number(purchaseConditions.free_vol_price_min)) {
      // eslint-disable-next-line no-unused-vars
      const marketplaceShipmentPrice = (subtotalValue < marketplace?.free_shipment_price) ? marketplace?.shipment_price || 0 : 0
      // console.log(marketplaceShipmentPrice)
      shippingPriceValue = (purchaseConditions.shipping_price || 0)
    }
  }

  if (comeFromShipmentChange) {
    shippingPriceValue = order.shipping_price
  }

  if (order.status_id === PENALIZATION_STATUS) {
    shippingPriceValue = 0
  }
  const totalValue = total(activeItems, igicValue, shippingInsuranceValue, shippingPriceValue, shipMode, order?.discount, order?.wallet_amount_used) + ((isCustom) ? order?.custom_order_price : 0)

  await supabaseClient
    .from('orders')
    .update({
      subtotal: subtotalValue,
      total_saving: savingsValue,
      syniva_price: synivaPriceValue,
      shipping_price: (order.status_id === PENALIZATION_STATUS) ? 0 : (comeFromShipmentChange) ? order.shipping_price : shippingPriceValue,
      shipping_insurance_price: shippingInsuranceValue,
      igic: igicValue,
      total: totalValue,
      updated_by: supabaseClient.auth.user()?.email
    })
    .eq('id', orderId)

  await updateCalculatedTotalOrder(orderId)

  return {
    shippingInsurance: Math.abs(order.shipping_insurance_price - shippingInsuranceValue),
    igic: Math.abs(order.igic - igicValue)
  }
}

export const getUserAddresses = async (userId: number): Promise<[number | null, number | null]> => {
  const { data, error } = await supabaseClient
    .from('users')
    .select('default_shipping_address_id, default_billing_address_id')
    .eq('id', userId)
    .single()

  if (error) {
    return [null, null]
  }

  const shippingAddress = data.default_shipping_address_id || null
  const billingAddress = data.default_billing_address_id || null

  return [shippingAddress, billingAddress]
}

export const getShippingMethod = async (shippingMethodId: number | null) => {
  if (!shippingMethodId) {
    return null
  }

  const { data, error } = await supabaseClient
    .from('shipment_methods')
    .select('id, name, price')
    .eq('id', shippingMethodId)
    .single()

  if (error) {
    return null
  }

  return data
}

export const getShipmentData = async (orderId: any) => {
  if (!orderId) {
    return null
  }

  const { data, error } = await supabaseClient
    .from('shipments')
    .select('id, tracking_id, type, delivery_note, tracking_url, shipper_type')
    .eq('order_id', orderId)
    .single()

  if (error) {
    return null
  }

  return data
}

export const getOrderItems = async (orderId: number) => {
  if (!orderId) {
    return null
  }

  const { data, error } = await supabaseClient
    .from('items')
    .select('id, name, syniva_price, price, disabled')
    .eq('deleted', false)
    .eq('order_id', orderId)

  if (error) {
    return null
  }

  return data
}

export const prepareBIReport = async (dates: any) => {
  const { data, error } = await supabaseClient.rpc('bi_report_dates', { start_date: dates[0], end_date: dates[1] })
  if (error) {
    console.error(error)
    return { data: [], error }
  }
  return { data, error: null }
}

export const prepareBankReport = async (dates: any) => {
  const { data, error } = await supabaseClient.rpc('banks_report_dates', { start_date: dates[0], end_date: dates[1] })
  if (error) {
    console.error(error)
    return { data: [], error }
  }
  return { data, error: null }
}

export const updateOrderAddresses = async (form: any, type: string, orderId: number) => {
  const dataAddress = {
    email: form?.form?.getFieldValue('email'),
    street: form?.form?.getFieldValue('street'),
    number: form?.form?.getFieldValue('number'),
    city: form?.form?.getFieldValue('city'),
    postal_code: form?.form?.getFieldValue('postal_code'),
    name: form?.form?.getFieldValue('name'),
    nif: form?.form?.getFieldValue('nif'),
    phone_number: form?.form?.getFieldValue('phone_number'),
    surnames: form?.form?.getFieldValue('surnames'),
    type: type,
    additional_info: form?.initialValues?.additional_info,
    is_company: form?.initialValues?.is_company,
    island_id: form?.initialValues?.island_id,
    user_id: form?.initialValues?.user_id
  }

  const { data: user, error: userError } = await supabaseClient
    .from('users')
    .select('id, default_billing_address_id, default_shipping_address_id')
    .eq('id', dataAddress.user_id)

  if (userError) {
    return { error: userError }
  }

  let newAddr: any
  let orderUpdated: any

  if (user) {
    // Create/Edit the new address
    if (user[0].default_billing_address_id === form?.initialValues?.id || user[0]?.default_shipping_address_id === form?.initialValues?.id) {
      newAddr = await supabaseClient
        .from('addresses')
        .insert(dataAddress)
    } else {
      newAddr = await supabaseClient
        .from('addresses')
        .update(dataAddress)
        .eq('id', form?.initialValues?.id)
    }

    // Update the order
    if (newAddr.data !== null) {
      if (type === 'shipping') {
        orderUpdated = await supabaseClient
          .from('orders').update({ shipping_address_id: newAddr.data[0].id })
          .eq('id', orderId)
      } else if (type === 'billing') {
        orderUpdated = await supabaseClient
          .from('orders').update({ billing_address_id: newAddr.data[0].id })
          .eq('id', orderId)
      }
    } else {
      return { error: newAddr.error }
    }
    if (orderUpdated.data) {
      return { dataAddress: dataAddress, newAddr: newAddr }
    } else {
      return { error: orderUpdated.error }
    }
  }
}

export const generateHorizontalAudit = async (dates: Date[]) => {
  // Retrieve information about orders and prepare a report
  const auditArrayPromises = []

  auditArrayPromises.push(supabaseClient.from('audit_orders').select('created_at, record, resource_id').order('created_at', { ascending: true }).gte('created_at', dates[0]).lte('created_at', dates[1]))
  auditArrayPromises.push(supabaseClient.from('audit_shipments').select('created_at, record, order_id').order('created_at', { ascending: true }).gte('created_at', dates[0]).lte('created_at', dates[1]))
  auditArrayPromises.push(supabaseClient.from('audit_market_orders').select('created_at, record, order_id').order('created_at', { ascending: true }).gte('created_at', dates[0]).lte('created_at', dates[1]))
  auditArrayPromises.push(supabaseClient.from('audit_supplements').select('created_at, record, order_id').order('created_at', { ascending: true }).gte('created_at', dates[0]).lte('created_at', dates[1]))

  const data = await Promise.all(auditArrayPromises)

  // dataConcatenated = [].concat.apply([], data.map(x => x.data))

  const shipmentProcessedList: any[] = []
  const moProcessedList: any[] = []
  const supplementsProcessedList: any[] = []

  const finalDataList: any[] = []

  let insertedByOrder = 0
  const processedList: any[] = []

  if (data && data[0]) {
    const orders = data[0]

    orders?.data?.forEach(o => {
      if (!processedList.includes(o.resource_id)) {
        let rowOrderInfo = {
          mo_date: '',
          marketplace: '',
          purchase_amount: 0,
          sup_date: '',
          transport_pending: '',
          transport_admitted: '',
          transportSupplement_pending: '',
          transportSupplement_admitted: '',
          price_pending: '',
          price_admitted: '',
          iva_pending: '',
          iva_admitted: '',
          other_pending: '',
          other_admitted: '',
          tariff_pending: '',
          tariff_admitted: '',
          igic_pending: '',
          igic_admitted: '',
          shipping_insurance_pending: '',
          shipping_insurance_admitted: '',
          vol_pending: '',
          vol_admitted: '',
          amount: 0,
          shipment_method: '',
          shipper_id: ''
        }
        const orderGroup = orders.data.filter(x => x.resource_id === o.resource_id)
        if (orderGroup.length > 0) {
          // Prepare data for shipment
          orderGroup.forEach(order => {
            const currentOrder = order.record

            const orderRow = {
              id: currentOrder.id,
              status: currentOrder.status_id,
              device: currentOrder?.device || '',
              user_id: currentOrder.user_id,
              return_request: currentOrder.return_date,
              cancel_request: currentOrder.cancelation_date,
              pending_payment: null,
              requested: null,
              processed: null,
              cancelled: null,
              returned: null,
              supplement: null,
              admitted: null,
              no_stock: null,
              prepared: null,
              sent: null,
              delivered: null,
              penalty: null,
              credit: null,
              mo_date: '',
              marketplace: '',
              purchase_amount: 0
            }

            if (currentOrder.status_id === 0) {
              orderRow.pending_payment = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 1) {
              orderRow.requested = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 2) {
              orderRow.processed = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 3) {
              orderRow.cancelled = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 4) {
              orderRow.returned = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 5) {
              orderRow.supplement = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 6) {
              orderRow.admitted = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 7) {
              orderRow.no_stock = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 8) {
              orderRow.prepared = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 9) {
              orderRow.sent = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 10) {
              orderRow.delivered = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 11) {
              orderRow.penalty = currentOrder.updated_at || currentOrder.created_at
            } else if (currentOrder.status_id === 12) {
              orderRow.credit = currentOrder.updated_at || currentOrder.created_at
            }

            rowOrderInfo = Object.assign({}, rowOrderInfo, removeNull(orderRow))
          })
        }

        // market_orders
        const marketOrders = data[2]?.data?.filter(x => x.order_id === o.resource_id)
        if (marketOrders && marketOrders.length > 0) {
          // Prepare data for market orders
          marketOrders.forEach(mo => {
            const currentMarketOrder = mo.record
            if (!moProcessedList.includes(currentMarketOrder.id) && currentMarketOrder.deleted_by === null) {
              // console.log(`Processing market_order ${currentMarketOrder.id} data for order ${rowOrderInfo.id}`)

              const rowMarketOrder = Object.assign({
                mo_date: '',
                marketplace: '',
                purchase_amount: 0
              }, rowOrderInfo)
              rowOrderInfo.mo_date = rowMarketOrder.mo_date = currentMarketOrder.created_at
              rowOrderInfo.marketplace = rowMarketOrder.marketplace = (currentMarketOrder.marketplace_legal_name) ? currentMarketOrder.marketplace_legal_name : ''
              rowOrderInfo.purchase_amount = rowMarketOrder.purchase_amount = currentMarketOrder.purchase_amount
              finalDataList.push(rowMarketOrder)
              moProcessedList.push(currentMarketOrder.id)
              insertedByOrder += 1
            }
          })
        }

        // market_orders
        const supplements = data[3]?.data?.filter(x => x.order_id === o.resource_id)
        if (supplements && supplements.length > 0) {
          // Prepare data for shipment
          supplements.forEach(sup => {
            const currentSupplement = sup.record
            if (!supplementsProcessedList.includes(currentSupplement.id) && currentSupplement.deleted === false) {
              // console.log(`Processing suplements ${currentSupplement.id} data for order ${rowOrderInfo.id}`)

              const rowSupplement = Object.assign({
                sup_date: '',
                transport_pending: '',
                transport_admitted: '',
                transportSupplement_pending: '',
                transportSupplement_admitted: '',
                price_pending: '',
                price_admitted: '',
                iva_pending: '',
                iva_admitted: '',
                other_pending: '',
                other_admitted: '',
                tariff_pending: '',
                tariff_admitted: '',
                igic_pending: '',
                igic_admitted: '',
                shipping_insurance_pending: '',
                shipping_insurance_admitted: '',
                vol_pending: '',
                vol_admitted: '',
                amount: 0
              }, rowOrderInfo)
              rowOrderInfo.sup_date = rowSupplement.sup_date = currentSupplement.created_at
              rowOrderInfo.transport_pending = rowSupplement.transport_pending = (currentSupplement.description === 'transportToPeninsula' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.transport_admitted = rowSupplement.transport_admitted = (currentSupplement.description === 'transportToPeninsula' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.transportSupplement_pending = rowSupplement.transportSupplement_pending = (currentSupplement.description === 'transportSupplement' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.transportSupplement_admitted = rowSupplement.transportSupplement_admitted = (currentSupplement.description === 'transportSupplement' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.price_pending = rowSupplement.price_pending = (currentSupplement.description === 'priceChange' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.price_admitted = rowSupplement.price_admitted = (currentSupplement.description === 'priceChange' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.iva_pending = rowSupplement.iva_pending = ((currentSupplement.description === 'reducedIva' || currentSupplement.description === 'reducedIvaSecondHand' || currentSupplement.description === 'reducedIvaBreakdown' || currentSupplement.description === 'reducedIvaEU' || currentSupplement.description === 'reducedIvaShipment') && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.iva_admitted = rowSupplement.iva_admitted = ((currentSupplement.description === 'reducedIva' || currentSupplement.description === 'reducedIvaSecondHand' || currentSupplement.description === 'reducedIvaBreakdown' || currentSupplement.description === 'reducedIvaEU' || currentSupplement.description === 'reducedIvaShipment') && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.other_pending = rowSupplement.other_pending = (currentSupplement.description === 'other' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.other_admitted = rowSupplement.other_admitted = (currentSupplement.description === 'other' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.tariff_pending = rowSupplement.tariff_pending = (currentSupplement.description === 'tariff' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.tariff_admitted = rowSupplement.tariff_admitted = (currentSupplement.description === 'tariff' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.igic_pending = rowSupplement.igic_pending = (currentSupplement.description === 'igic' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.igic_admitted = rowSupplement.igic_admitted = (currentSupplement.description === 'igic' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.shipping_insurance_pending = rowSupplement.shipping_insurance_pending = (currentSupplement.description === 'shippingInsurancePrice' && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.shipping_insurance_admitted = rowSupplement.shipping_insurance_admitted = (currentSupplement.description === 'shippingInsurancePrice' && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.vol_pending = rowSupplement.vol_pending = (currentSupplement.description === null && currentSupplement.status_id === 0) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.vol_admitted = rowSupplement.vol_admitted = (currentSupplement.description === null && currentSupplement.status_id === 6) ? currentSupplement.updated_at || currentSupplement.created_at : ''
              rowOrderInfo.amount = rowSupplement.amount = currentSupplement.supplement
              finalDataList.push(rowSupplement)
              supplementsProcessedList.push(currentSupplement.id)
              insertedByOrder += 1
            }
          })
        }

        // shipments
        const shipments = data[1]?.data?.filter(x => x.order_id === o.resource_id).sort((prev, next) => Date.parse(next.created_at) - Date.parse(prev.created_at))
        if (shipments && shipments.length > 0) {
          // Prepare data for shipment
          // order by created_at descending
          shipments.forEach(ship => {
            const currentShipment = ship.record
            if (!shipmentProcessedList.includes(currentShipment.id)) {
              // console.log(`Processing Shipment ${currentShipment.id} data for order ${rowOrderInfo.id}`)

              const rowShipment = Object.assign({
                shipment_method: '',
                shipper_id: ''
              }, rowOrderInfo)
              rowOrderInfo.shipment_method = rowShipment.shipment_method = currentShipment.shipper_type
              rowOrderInfo.shipper_id = rowShipment.shipper_id = (currentShipment.shipper_type?.toLowerCase().includes('correos')) ? currentShipment.tracking_id : TIPSA_AGENCY_CODE + (currentShipment?.type?.toLowerCase().includes('directo') ? TIPSA_AGENCY_SERVICE_TYPE_DIRECT : TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED) + currentShipment.delivery_note
              finalDataList.push(rowShipment)
              shipmentProcessedList.push(currentShipment.id)
              insertedByOrder += 1
            }
          })
        }

        if (insertedByOrder <= 0) {
          finalDataList.push(rowOrderInfo)
        }
        processedList.push(o.resource_id)
        insertedByOrder = 0
      }
    })

    // Fix the dates and calculate days between states
    finalDataList.forEach((row, index) => {
      // Provider transit: days between processed status and admitted
      let d1 = new Date(row.processed)
      let d2 = new Date(row.admitted)
      if (d1 && d2) {
        row.prov_transit = ''
        const transitProviderDays = Math.ceil((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
        if (!Number.isNaN(transitProviderDays)) {
          row.prov_transit = transitProviderDays.toString()
        }
      }

      // syniva management: days between admitted status and sent status
      d1 = new Date(row.admitted)
      d2 = new Date(row.sent)
      if (d1 && d2) {
        row.syniva_transit = ''
        const transitSynivaDays = Math.ceil((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
        if (!Number.isNaN(transitSynivaDays)) {
          row.syniva_transit = transitSynivaDays.toString()
        }
      }

      // Provider sent: days between sent status and delivered status
      d1 = new Date(row.sent)
      d2 = new Date(row.delivered)
      if (d1 && d2) {
        row.syniva_sent = ''
        const sentSynivaDays = Math.ceil((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
        if (!Number.isNaN(sentSynivaDays)) {
          row.syniva_sent = sentSynivaDays.toString()
        }
      }

      // full sent: days between resquested status and delivered status
      d1 = new Date(row.requested)
      d2 = new Date(row.delivered)
      if (d1 && d2) {
        row.full_sent = ''
        const fullSentDays = Math.ceil((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
        if (!Number.isNaN(fullSentDays)) {
          row.full_sent = fullSentDays.toString()
        }
      }

      if (row.status >= 0) {
        row.status = MapStatusIDToText(row.status)
      }

      if (index > 0) {
        row.iva_admitted = formatDateTime(row.iva_admitted, true)
        row.iva_pending = formatDateTime(row.iva_pending, true)
        row.mo_date = formatDateTime(row.mo_date, true)
        row.other_admitted = formatDateTime(row.other_admitted, true)
        row.other_pending = formatDateTime(row.other_pending, true)
        row.tariff_admitted = formatDateTime(row.tariff_admitted, true)
        row.tariff_pending = formatDateTime(row.tariff_pending, true)
        row.igic_admitted = formatDateTime(row.igic_admitted, true)
        row.igic_pending = formatDateTime(row.igic_pending, true)
        row.shipping_insurance_pending = formatDateTime(row.shipping_insurance_pending, true)
        row.shipping_insurance_admitted = formatDateTime(row.shipping_insurance_admitted, true)
        row.price_admitted = formatDateTime(row.price_admitted, true)
        row.price_pending = formatDateTime(row.price_pending, true)
        row.requested = formatDateTime(row.requested, true)
        row.sup_date = formatDateTime(row.sup_date, true)
        row.transport_admitted = formatDateTime(row.transport_admitted, true)
        row.transport_pending = formatDateTime(row.transport_pending, true)
        row.vol_admitted = formatDateTime(row.vol_admitted, true)
        row.vol_pending = formatDateTime(row.vol_pending, true)
        row.pending_payment = formatDateTime(row.pending_payment, true)
        row.processed = formatDateTime(row.processed, true)
        row.cancelled = formatDateTime(row.cancelled, true)
        row.returned = formatDateTime(row.returned, true)
        row.supplement = formatDateTime(row.supplement, true)
        row.admitted = formatDateTime(row.admitted, true)
        row.no_stock = formatDateTime(row.no_stock, true)
        row.prepared = formatDateTime(row.prepared, true)
        row.sent = formatDateTime(row.sent, true)
        row.delivered = formatDateTime(row.delivered, true)
        row.penalty = formatDateTime(row.penalty, true)
        row.credit = formatDateTime(row.credit, true)
      }
    })

    return finalDataList
  }
  return []
}

// All users and All info
export const getAllUsers = async () => {
  const { count } = await supabaseClient.from('users')
    .select('id', { count: 'exact', head: true })
    .eq('deleted', false)

  let maxIterations = Math.floor((count || 0) / MAX_ROWS_SALES)

  if ((count || 0) % MAX_ROWS_SALES > 0) {
    maxIterations += 1
  }

  const iterateArray = new Array(maxIterations)
  const usersArray = []

  const range = [0, MAX_ROWS_SALES - 1]
  // eslint-disable-next-line no-unused-vars
  for (const index of iterateArray) {
    const { data: users, error: userError } = await supabaseClient.from('users')
      .select('id, email, name, orders!user(*, supplements(*)), role_id(id, name), created_at, default_shipping_address_id(name, surnames, nif)')
      .eq('deleted', false)
      .in('orders.status_id', [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS, DELIVERED_STATUS, ORDER_STATUS_ID_ON_HOLD, CANCELLED_STATUS, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, REFUND_STATUS, RETURN_STATUS])
      .range(range[0], range[1])

    if (userError) continue

    usersArray.push(users)
    range[0] += MAX_ROWS_SALES
    range[1] += MAX_ROWS_SALES
  }

  let querySucess = [] as any[]
  usersArray.forEach((r) => {
    if (r) {
      querySucess = [...querySucess, ...r]
    }
  })

  return querySucess
}

export const getUsersWithoutOrdersFunction = async (dates: any) => {
  const { data, error } = await supabaseClient.rpc('get_users_without_orders', { start_date: dates[0], end_date: dates[1] })

  if (error) {
    console.error(error)
    return []
  }
  return data || []
}

export const getAllMarketplaces = async () => {
  const { count } = await supabaseClient.from('marketplaces')
    .select('id', { count: 'exact', head: true })
    .eq('deleted', false)

  let maxIterations = Math.floor((count || 0) / MAX_ROWS_SALES)

  if ((count || 0) % MAX_ROWS_SALES > 0) {
    maxIterations += 1
  }

  const iterateArray = new Array(maxIterations)
  const promiseArray = []

  const range = [0, MAX_ROWS_SALES - 1]
  // eslint-disable-next-line no-unused-vars
  for (const index of iterateArray) {
    promiseArray.push(await supabaseClient.from('marketplaces')
      .select('id, name, logo_url, description, status, url, is_in_home, extra_info, is_in_app, shipment_price, free_shipment_price, weight, iva_percent, created_at, updated_at, original_url, is_custom_shop,' +
      'igic_types(id, name, value),' +
      'marketplace_category_relations(' +
      'marketplace_categories(name))')
      .eq('deleted', false)
      .range(range[0], range[1]))
    range[0] += MAX_ROWS_SALES
    range[1] += MAX_ROWS_SALES
  }

  const results = await Promise.all(promiseArray)

  let querySucess = [] as any[]
  results.forEach((r) => {
    if (r.data) {
      querySucess = [...querySucess, ...r.data]
    }
  })

  return querySucess
}

export const getGroupedMarketplace = async (siteName: string) => {
  const dataToReturn: any = {
    internal: [],
    related: []
  }

  const promiseArray = [
    supabaseClient.from('marketplaces')
      .select('id, name, is_custom_shop, extra_info')
      .eq('is_custom_shop', false)
      .eq('deleted', false)
      .ilike('name', `%${siteName.toLowerCase()}%`),
    supabaseClient.from('marketplaces')
      .select('id, name, is_custom_shop, extra_info, internal_shop_id')
      .eq('is_custom_shop', true)
      .eq('deleted', false)
      .ilike('name', `%${siteName.toLowerCase()}%`)
  ]

  const results = await Promise.all(promiseArray)

  results.forEach((r: any, index: number) => {
    if (index === 0) {
      dataToReturn.internal = r.data[0]
    } else {
      dataToReturn.related = r.data
    }
  })

  if (!dataToReturn?.internal || dataToReturn?.internal?.length === 0) {
    const internalShopID = dataToReturn.related.map((r: any) => r.internal_shop_id).filter((x: any) => x !== null)

    if (internalShopID.length > 0) {
      const { data } = await supabaseClient.from('marketplaces')
        .select('id, name, is_custom_shop, extra_info')
        .eq('is_custom_shop', false)
        .eq('deleted', false)
        .in('id', internalShopID)

      if (data) {
        dataToReturn.internal = data
      }
    }
  }

  return dataToReturn
}

export const registerAccess = async (operation: string, userId: string, email: string | null) => {
  const { data: registry, error: accessError } = await supabaseClient.from('audit_user_access')
    .insert({
      operation: operation,
      resource_id: userId,
      email: email
    }).single()

  if (accessError) {
    console.log(accessError)
  }

  await supabaseClient.from('users')
    .update({
      last_access: registry?.created_at
    })
    .eq('supabase_user_id', userId)
}

export const updateIssueDeadlines = (marketplaceId: number) => {
  fetch(`${API_URL}/actions/issues/updateIssueDeadlines/${marketplaceId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const checkIssueDeadline = async (orderId: number, issueId?: number) => {
  const { data: supData } = await supabaseClient.from('supplements').select('id, created_at')
    .eq('order_id', orderId)
    .eq('volumetric', true)
    .eq('deleted', false)
    .single()

  const { data: orderData } = await supabaseClient.from('orders').select('id, marketplace_id(id, return_days)')
    .eq('id', orderId)
    .single()

  let issueDeadline = null
  if (orderData && orderData.marketplace_id && orderData.marketplace_id.return_days && supData) {
    if (supData?.created_at && orderData.marketplace_id.return_days > 0) {
      issueDeadline = getReturnDayLimit(supData.created_at, orderData.marketplace_id.return_days)
      if (issueId) {
        await supabaseClient.from('issues').update({
          issue_deadline: issueDeadline.toISOString()
        }).eq('id', issueId)
      } else {
        await supabaseClient.from('issues').update({
          issue_deadline: issueDeadline.toISOString()
        }).eq('order_id', orderId)
      }
    }
  }
}

export const addTransportSupplementOrRefund = async (orderId: number, description: string, statusId: number, supAmount: number) => {
  await supabaseClient.from('supplements')
    .insert({
      order_id: orderId,
      description: description,
      supplement: supAmount.toFixed(2),
      status_id: statusId,
      volumetric: false,
      deleted: (supAmount === 0)
    })
}

const applySorterToQuery = (query: PostgrestFilterBuilder<any>, sorter: sorter) => {
  return query.order(sorter.field, { ascending: sorter.ascending })
}

const applyFilterToQuery = (query: PostgrestFilterBuilder<any>, filters: filter[]) => {
  filters.forEach((f: filter) => {
    switch (f.operator) {
      case 'eq':
        query.eq(f.field, f.value)
        break
      case 'gt':
        query.gt(f.field, f.value)
        break
      case 'gte':
        query.gte(f.field, f.value)
        break
      case 'lt':
        query.lt(f.field, f.value)
        break
      case 'lte':
        query.lte(f.field, f.value)
        break
      case 'in':
        query.in(f.field, f.value)
        break
      case 'not':
        query.not(f.field, f.secondOp as any, f.value)
        break
      case 'is':
        query.is(f.field, f.value)
        break
      case 'ilike':
        query.ilike(f.field, `%${f.value}%`)
        break
      case 'like':
        query.like(f.field, `%${f.value}%`)
        break
    }
  })
  return query
}

export const getSupaData = async (resource: string, select: string, cPage: number, cPageSize: number, filters: filter[], sorter: sorter) => {
  let counter = 0

  let supabaseCounterQuery = supabaseClient.from(resource)
    .select(`${select}`, { count: 'exact', head: true })

  supabaseCounterQuery = applyFilterToQuery(supabaseCounterQuery, filters)

  const { count } = await supabaseCounterQuery
  counter = count || 0

  let maxIterations = Math.floor((counter || 0) / MAX_ROWS_SALES)

  if ((counter || 0) % MAX_ROWS_SALES > 0) {
    maxIterations += 1
  }

  const iterateArray = new Array(maxIterations)
  const promiseArray = []

  const range = [0, MAX_ROWS_SALES - 1]
  // eslint-disable-next-line no-unused-vars
  for (const index of iterateArray) {
    let supabaseDataQuery = supabaseClient.from(resource).select(select)

    supabaseDataQuery = applyFilterToQuery(supabaseDataQuery, filters)
    supabaseDataQuery = applySorterToQuery(supabaseDataQuery, sorter)

    promiseArray.push(supabaseDataQuery.range(range[0], range[1]))
    range[0] += MAX_ROWS_SALES
    range[1] += MAX_ROWS_SALES
  }

  const results = await Promise.all(promiseArray)

  let querySucess: any[] = []
  results.forEach((r) => {
    if (r.data) {
      querySucess = [...querySucess, ...r.data]
    }
  })
  return { data: querySucess, count: counter }
}

export const getReferredBenefits = async (id: number) => {
  const { data } = await supabaseClient
    // eslint-disable-next-line camelcase
    .from<definitions['referral_benefits'] & { referred_user_id: definitions['users'] } & { referrer_user_id: definitions['users'] }>('referral_benefits')
    .select('status, benefit_amount, created_at, referred_expired_at, referrer_user_id(id, name), referred_user_id(name)')
    .eq('referred_user_id', id)

  return data || []
}

export const getReferrerBenefits = async (id: number) => {
  const { data } = await supabaseClient
    // eslint-disable-next-line camelcase
    .from<definitions['referral_benefits'] & { referred_user_id: definitions['users'] }>('referral_benefits')
    .select('status, benefit_amount, created_at, referrer_expired_at, referred_user_id(name)')
    .eq('referrer_user_id', id)

  return data || []
}

export const getOrdersWithWalletAmountUsed = async (id: number) => {
  const { data } = await supabaseClient
    // eslint-disable-next-line camelcase
    .from<definitions['orders'] & { user_id: definitions['users'] }>('orders')
    .select('id, wallet_amount_used, created_at, user_id(name)')
    .eq('user_id', id)
    .gt('wallet_amount_used', 0)
    .gt('status_id', ORDER_STATUS_ID_PENDING_PAYMENT)

  return data || []
}

export const findPendingMarketOrdersWithoutInvoice = async (value: string) => {
  const { data } = await supabaseClient
    .rpc('filter_pending_market_orders', { value })

  return data
}

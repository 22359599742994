import {
  useShow,
  IResourceComponentsProps,
  useTranslate
} from '@pankod/refine-core'

import { Row, Col, Alert, Icons } from '@pankod/refine-antd'

import { User, definitions } from 'interfaces'
import { UserAddresses } from '../../components/orders/user_addresses'
import { OrderDetails } from '../../components/orders/details'
import { OrderItems } from '../../components/orders/items'
import { MarketOrders } from '../../components/orders/market_orders'
import { Supplements } from '../../components/orders/supplements'
import { Refunds } from '../../components/orders/refunds'
import { EstimatedCosts } from '../../components/orders/EstimatedCosts'
import { useEffect, useState } from 'react'
import { calculateReferralAndWalletAmounts, calculateReferralAndWalletAsync } from 'utility'

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const [warningWalletAmountUsed, setWarningWalletAmountUsed] = useState(false)

  const addressFields = 'id, email, name, surnames, phone_number, nif, additional_info, is_company, full_address'
  const { queryResult } = useShow<definitions['Order'] & { user: User }>({
    metaData: {
      select:
        '*, payment_method(id, name), user(id, name, email, created_at, gender, birthday, wallet_balance, referred_by_user_id(referral_benefits!referrer_user_id(id, benefit_amount)), referral_benefits!referrer_user_id(status, benefit_amount),' +
        'orders!user(id, total, created_at, status_id, syniva_price, shipping_price, shipping_insurance_price, custom_order_price, wallet_amount_used,' +
        'supplements(supplement), items(shipping_price_to_madrid), marketplace_id(id, shipment_price, free_shipment_price))), administrator(id, name), credit_card(number),' +
        'shipping_method_id, status(id, name), shipping_method(name, shipment_type, shipMode, price, volumetric_factor, price_additional_kg, shipping_method_price_ranges(*)),' +
        `shipping_address(${addressFields}, island(name, boat_extra_cost, plane_extra_cost)), billing_address(${addressFields}),
        supplements(id, status_id, volumetric, supplement, description, deleted), estimated_costs(id, status_id), refunds(*), wallet_amount_used,
        items(id, url, custom_order_site, shipping_price_to_madrid, price, disabled, syniva_price, iva, quantity, igic_price, igic_percent, has_accessories),
        marketplace_id(id, shipment_price, free_shipment_price, cif, legal_name, legal_address, province, postal_code, extra_info, is_custom_shop, iva_percent, igic_types(id, value)),
        issues(id)`
    }
  })

  const { data } = queryResult
  const order = data?.data

  const getTotalsReferral = async () => {
    const benefitData = await calculateReferralAndWalletAsync(order!.user)
    const { totalBenefits, totalWalletAmountUsed } = calculateReferralAndWalletAmounts(benefitData)
    setWarningWalletAmountUsed(totalWalletAmountUsed > totalBenefits)
  }

  useEffect(() => {
    if (order) {
      getTotalsReferral()
    }
  }, [order])

  if (!order) return <></>

  order.user.orders = order.user.orders?.sort((a, b) => b.id - a.id)

  // Filter out deleted relations
  order.shipping_method.shipping_method_price_ranges =
    order?.shipping_method.shipping_method_price_ranges.filter(f => !f.deleted) || []

  order.supplements = order.supplements.filter(s => !s.deleted)

  order.refunds = order.refunds.filter(s => !s.deleted)

  let supplementQuery: any = null
  let estimatedCostsQuery: any = null
  let refundQuery: any = null

  const setSuplementQuery = (value: any) => {
    if (supplementQuery === null) {
      supplementQuery = value
    }
  }

  const setEstimatedCostsQuery = (value: any) => {
    if (estimatedCostsQuery === null) {
      estimatedCostsQuery = value
    }
  }

  const setRefundQuery = (value: any) => {
    if (refundQuery === null) {
      refundQuery = value
    }
  }

  const refetchSup = () => {
    if (supplementQuery) supplementQuery.refetch()
  }
  const refetchRef = () => {
    if (refundQuery) refundQuery.refetch()
  }

  return (
    <>
      {warningWalletAmountUsed && (
        <Alert
          message={t('common.warning')}
          description={t('users.referral_benefits.warning_wallet_amount_used_desc')}
          type="warning"
          showIcon
          icon={<Icons.WarningOutlined />}
          style={{ marginBottom: 20 }}
        />
      )}
      <Row gutter={[15, 15]}>
        <Col xl={12} lg={24} xs={24}>
          <UserAddresses order={order} />
        </Col>

        <Col xl={12} xs={24}>
          <OrderDetails order={order} refetchFn={queryResult.refetch} />
        </Col>
      </Row>
      <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
        <Col xl={24}>
          <OrderItems order={order} orderQuery={queryResult} refetchSup={() => refetchSup()} />
        </Col>
      </Row>
      <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
        <Col xl={24}>
          <MarketOrders order={order} />
        </Col>
      </Row>
      <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
        <Col xl={24}>
          <Supplements order={order} orderQuery={queryResult} setSupQuery={setSuplementQuery} refetchRef={() => refetchRef()} />
        </Col>
      </Row>
      <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
        <Col xl={24}>
          <EstimatedCosts order={order} orderQuery={queryResult} setSupQuery={setEstimatedCostsQuery} />
        </Col>
      </Row>
      <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
        <Col xl={24}>
          <Refunds order={order} orderQuery={queryResult} setRefQuery={setRefundQuery} />
        </Col>
      </Row>
    </>
  )
}
